import { AsyncState } from 'state-domains/types';

import { MXDTimestamp } from '../utils';

export interface JobState extends AsyncState {
    jobs: Record<string, Job>;
    searchTerm?: string;
    offset?: number;
    limit?: number;
}

interface JobUser {
    user: string;
    id: string;
    createdBy?: string;
    createdAt?: MXDTimestamp;
}

export type JobUsers = Record<string, JobUser>;

export interface Job {
    id: string;
    parameters: {
        body: {
            project?: string;
            destination?: string[];
            exportTableIds?: { tableIds: string[]; tableViewIds: string[] };
        };
    };
    progressPercentage?: number;
    name: string;
    error: string;
    createdAt?: MXDTimestamp;
    createdBy?: string;
    status: string;
    result?: string;
    type: string;
    evoObjectLink?: string;
}

export interface Jobs {
    Job: Job[];
}

export interface JobError {
    error: Error;
}

export interface JobCompleteEvent {
    detail: string;
    referenceDetail: string;
    detail1: string;
}

export enum JobExportType {
    Export = 'Export',
    ListExport = 'List-Export',
    Import = 'Import',
    TableExport = 'Table-Export',
    RankedColumnRecompute = 'Ranked-Column-Recompute',
    Recalculation = 'Recalculation',
}

export enum JobStatus {
    FAILED = 'Failed',
    EXPIRED = 'Expired',
    CANCELLED = 'Cancelled',
    SUCCEEDED = 'Succeeded',
    SUBMITTED = 'Submitted',
    PENDING = 'Pending',
    RUNNABLE = 'Runnable',
    STARTING = 'Starting',
    RUNNING = 'Running',
}
